<template>
  <div>
    <div class="flex gap-6">
      <slot>
        <div
          v-for="tab in tabs"
          :key="tab.value"
          class="cursor-pointer border-b-2"
          :class="
            selectedTab === tab.value
              ? 'border-bb-brand-purple text-bb-brand-purple'
              : 'text-bb-text-default border-white hover:border-neutral-50'
          "
          @click="selectTab(tab)"
        >
          <p class="px-6 pb-2">
            {{ tab.label }}
            <slot
              v-if="tab.displayBadge"
              name="badge"
            ></slot>
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectTab(tab) {
      this.$emit('select-tab', tab.value)
      this.$router.push({ query: { ...this.$route.query, tab: tab.value } })
    },
  },
}
</script>
